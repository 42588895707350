// Import required libraries using package manager
import React from "react";
import { render } from "react-dom";
import Nav from "./Nav.js";
import GroupContent from "./GroupContent.js";

class TopicGroupMain extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="topic-group-main">
        <Nav content={this.props.content} theme={this.props.theme} />

        <div className="tg-bar-main" />

        <GroupContent
          tg_class={"tg-content-main"}
          content={this.props.content}
          theme={this.props.theme}
          size={this.props.size}
        />

        {/* <svg width={this.state.screenWidth} height={this.state.screenHeight}>
            <rect
            width={this.state.screenWidth}
            height={this.state.screenHeight}
            fill="var(--iah_red)"
            />
            </svg> */}
        {/* <ProjectCard
            image="/tmp-6.d8609974.jpg"
            size={{
            width: this.state.screenWidth,
            height: this.state.screenHeight
            }}
            /> */}
      </div>
    );
  }
}

export default TopicGroupMain;
