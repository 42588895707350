// Import required libraries using package manager
import React from "react";
import { render } from "react-dom";
import { Router, Link } from "@reach/router";
import About from "./js/About";
import Contact from "./js/Contact";
import Services from "./js/Services";
import Portfolio from "./js/Portfolio";
import ProjectPage from "./js/ProjectPage";
import PortfolioData from "./data/PortfolioData.json";

const TARGET_DIV = "AppDiv";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.onResize = this.onResize.bind(this);

    this.state = {
      loadError: "",
      portfolio: PortfolioData.PortfolioData,
      screenWidth: 1000,
      screenHeight: 500,
      marginTop: 10,
      marginBottom: 10,
      marginLeft: 10,
      marginRight: 10
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize, false);
    this.onResize();
  }

  onResize() {
    let divWidth = document.getElementById(TARGET_DIV).offsetWidth;
    let divHeight = document.getElementById(TARGET_DIV).offsetHeight;
    this.setState({
      screenWidth: divWidth,
      screenHeight: divHeight,
      marginTop: divHeight * 0.05,
      marginBottom: divHeight * 0.1,
      marginLeft: divWidth * 0.05,
      marginRight: divWidth * 0.025
    });
  }

  render() {
    let dateObj = new Date().getFullYear();

    return (
      <div className="app-grid">
        <aside className="htop" />
        <header className="iah_header header_main">
          <div className="iah_header header_left" />
          <Link className="iah_title" to="/">
            <img
              src={window.location.origin + "/img/asset/iah_title.svg"}
              className="iah_svg"
              alt="information art house"
            />
          </Link>
          <Link className="iah_logo" to="/">
            <img
              src={window.location.origin + "/img/asset/iah_logo_style_1.svg"}
              className="iah_svg"
              alt="information art house"
            />
          </Link>
          <div className="iah_header header_right" />
        </header>
        <Router className="app-content">
          <About path="about" />
          <Services path="/services" />
          <Contact path="/contact" />
          <ProjectPage
            path="/project/:theme/:id"
            portfolio={this.state.portfolio}
            size={{
              width: this.state.screenWidth,
              height: this.state.screenHeight
            }}
          />
          <Portfolio
            path="/"
            portfolio={this.state.portfolio}
            size={{
              width: this.state.screenWidth,
              height: this.state.screenHeight
            }}
          />
        </Router>
        <footer className="iah_footer">
          <aside className="iah_footer_text">
            {"\u00A9"} {dateObj} Information Art House
          </aside>
        </footer>
      </div>
    );
  }
}

render(<App />, document.getElementById(TARGET_DIV));
