// Import required libraries using package manager
import React from "react";
import { render } from "react-dom";
import { Link } from "@reach/router";

class Nav extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <nav className="tg-nav ">
        <ul className="iah_links">
          <li className="iah_li">
            <Link className="iah_link" to="/about">
              About
            </Link>
          </li>
          <li className="iah_li">
            <Link className="iah_link" to="/services">
              Services
            </Link>
          </li>
          <li className="iah_li">
            <Link className="iah_link" to="/contact">
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Nav;
