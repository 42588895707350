// Import required libraries using package manager
import React from "react";
import { render } from "react-dom";
import ProjectCard from "./ProjectCard.js";
import _ from "lodash";

class GroupContent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={this.props.tg_class}>
        {this.props.content[0].map(data => (
          <ProjectCard
            key={data.id}
            id={data.id}
            theme={this.props.theme}
            image={data.thumbnail}
            caption={data.name}
            size={this.props.size}
          />
        ))}
      </div>
    );
  }
}
export default GroupContent;
