// Import required libraries using package manager
import React from "react";
import { render } from "react-dom";
import { Link } from "@reach/router";
import _ from "lodash";

class ProjectPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleIndexClick = this.handleIndexClick.bind(this);

    this.state = {
      loadError: "",
      id: 0,
      images: [],
      code: "",
      description: "",
      title: "",
      color: {},
      active: 0
    };
  }

  componentDidMount() {
    this.getProject();
  }

  getProject() {
    let port = this.props.portfolio;
    let theme = this.props.theme;
    let id = this.props.id;
    let projects = [];
    _.map(port, function(data) {
      if (data.theme == theme) {
        projects.push(data.content);
      }
    });
    let out = {};
    _.forEach(projects[0], function(d) {
      if (d.id == id) {
        out = d;
      }
    });
    this.setState({
      id: out.id,
      images: out.images,
      code: out.code,
      description: out.description,
      title: out.name,
      color: out.color
    });

    fetch(window.location.origin + out.code)
      .then(function(response) {
        return response.text();
      })
      .then(function(data) {
        document.getElementById("project-code-block").innerText = data;
      })
      .catch(err => {
        this.setState({ loadError: err });
      });
  }

  handleIndexClick(e) {
    this.setState({
      active: +e.target.dataset.index
    });
  }

  render() {
    return (
      <div className="project-page">
        <div className="project-title">
          <h1 className="caption-title "> {this.state.title}</h1>

          <h3 className="caption-description ">{this.state.description}</h3>
        </div>
        <figure className="card project-content">
          <img
            className="card-image"
            src={window.location.origin + this.state.images[this.state.active]}
            alt={this.state.title}
          />
          <figcaption className="caption">
            <h3 className="caption-title">{this.state.title}</h3>
          </figcaption>
        </figure>

        <div className="project-carousel">
          {this.state.images.map((image, index) => (
            <a onClick={this.handleIndexClick} key={index}>
              <img
                data-index={index}
                src={window.location.origin + image}
                className={`carousel-image ${
                  index === this.state.active ? "active" : ""
                  }`}
                alt={this.state.title}
              />
            </a>
          ))}
        </div>
        <div className="project-code">
          <code id="project-code-block" />
        </div>
      </div>
    );
  }
}

export default ProjectPage;
