// Import required libraries using package manager
import React from "react";
import { render } from "react-dom";
import TopicGroupMain from "./TopicGroupMain.js";
import TopicGroup from "./TopicGroup.js";
import _ from "lodash";

class Portfolio extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tg1_theme: "",
      tg1_content: [],
      tg2_theme: "",
      tg2_content: [],
      tg3_theme: "",
      tg3_content: []
    };
  }

  static getDerivedStateFromProps({ portfolio }) {
    let [tg1, tg2, tg3] = [portfolio[0], portfolio[1], portfolio[2]];
    let tg1_content = _.values(_.pick(tg1, "content"));
    let tg1_theme = _.values(_.pick(tg1, "theme"))[0];
    let tg2_content = _.values(_.pick(tg2, "content"));
    let tg2_theme = _.values(_.pick(tg2, "theme"))[0];
    let tg3_content = _.values(_.pick(tg3, "content"));
    let tg3_theme = _.values(_.pick(tg3, "theme"))[0];

    return {
      tg1_theme,
      tg1_content,
      tg2_theme,
      tg2_content,
      tg3_theme,
      tg3_content
    };
  }

  render() {
    const {
      tg1_theme,
      tg1_content,
      tg2_theme,
      tg2_content,
      tg3_theme,
      tg3_content
    } = this.state;

    return (
      <div>
        <TopicGroupMain
          content={tg1_content}
          theme={tg1_theme}
          size={this.props.size}
        />
        <TopicGroup
          content={tg2_content}
          theme={tg2_theme}
          size={this.props.size}
        />
        <TopicGroup
          content={tg3_content}
          theme={tg3_theme}
          size={this.props.size}
        />
      </div>
    );
  }
}

export default Portfolio;
