module.exports = {
    "PortfolioData": [
	{
	    "theme": "photo",
	    "content": [
		{
		    "id": 11,
		    "name": "Tarawera River",
		    "description": "Pictures from the Tarawera River, New Zealand",
		    "color": {
			"dark": "var(--cs1-yellow-dark)",
			"primary": "var(--cs1-yellow)",
			"light": "var(--cs1-yellow-light)",
			"secondary": "var(--cs1-red-light)"
		    },
		    "thumbnail": "/data/project/photo/01-tarawera/thmb.jpg",
		    "images": [
			"/data/project/photo/01-tarawera/1.jpg",
			"/data/project/photo/01-tarawera/2.jpg",
			"/data/project/photo/01-tarawera/3.jpg"
		    ],
		    "code": "/data/project/photo/01-tarawera/code.txt"
		},
		{
		    "id": 12,
		    "name": "Kepler Track",
		    "description": "Pictures from the Kepler Track, New Zealand",
		    "color": {
			"dark": "var(--cs1-yellow-dark)",
			"primary": "var(--cs1-yellow)",
			"light": "var(--cs1-yellow-light)",
			"secondary": "var(--cs1-red-light)"
		    },
		    "thumbnail": "/data/project/photo/02-kepler/thmb.jpg",
		    "images": [
			"/data/project/photo/02-kepler/1.jpg",
			"/data/project/photo/02-kepler/2.jpg",
			"/data/project/photo/02-kepler/3.jpg"
		    ],
		    "code": "/data/project/photo/02-kepler/code.txt"
		},
		{
		    "id": 13,
		    "name": "Lake Waikaremoana",
		    "description": "Pictures from the Lake Waikaremoana, New Zealand",
		    "color": {
			"dark": "var(--cs1-yellow-dark)",
			"primary": "var(--cs1-yellow)",
			"light": "var(--cs1-yellow-light)",
			"secondary": "var(--cs1-red-light)"
		    },
		    "thumbnail": "/data/project/photo/03-lake_waikaremoana/thmb.jpg",
		    "images": [
			"/data/project/photo/03-lake_waikaremoana/1.jpg",
			"/data/project/photo/03-lake_waikaremoana/2.jpg",
			"/data/project/photo/03-lake_waikaremoana/3.jpg"
		    ],
		    "code": "/data/project/photo/03-lake_waikaremoana/code.txt"
		},
		{
		    "id": 14,
		    "name": "North Shore Auckland",
		    "description": "Pictures from the North Shore, Auckland, New Zealand",
		    "color": {
			"dark": "var(--cs1-yellow-dark)",
			"primary": "var(--cs1-yellow)",
			"light": "var(--cs1-yellow-light)",
			"secondary": "var(--cs1-red-light)"
		    },
		    "thumbnail": "/data/project/photo/04-north_shore/thmb.jpg",
		    "images": [
			"/data/project/photo/04-north_shore/1.jpg",
			"/data/project/photo/04-north_shore/2.jpg",
			"/data/project/photo/04-north_shore/3.jpg"
		    ],
		    "code": "/data/project/photo/04-north_shore/code.txt"
		},
		{
		    "id": 15,
		    "name": "Routeburn Track",
		    "description": "Pictures from the Routeburn Track, New Zealand",
		    "color": {
			"dark": "var(--cs1-yellow-dark)",
			"primary": "var(--cs1-yellow)",
			"light": "var(--cs1-yellow-light)",
			"secondary": "var(--cs1-red-light)"
		    },
		    "thumbnail": "/data/project/photo/05-routeburn/thmb.jpg",
		    "images": [
			"/data/project/photo/05-routeburn/1.jpg",
			"/data/project/photo/05-routeburn/2.jpg",
			"/data/project/photo/05-routeburn/3.jpg"
		    ],
		    "code": "/data/project/photo/05-routeburn/code.txt"
		},
		{
		    "id": 16,
		    "name": "Tahoe",
		    "description": "Pictures from the Tahoe National Forest, California",
		    "color": {
			"dark": "var(--cs1-yellow-dark)",
			"primary": "var(--cs1-yellow)",
			"light": "var(--cs1-yellow-light)",
			"secondary": "var(--cs1-red-light)"
		    },
		    "thumbnail": "/data/project/photo/06-tahoe/thmb.jpg",
		    "images": [
			"/data/project/photo/06-tahoe/1.jpg",
			"/data/project/photo/06-tahoe/2.jpg",
			"/data/project/photo/06-tahoe/3.jpg"
		    ],
		    "code": "/data/project/photo/06-tahoe/code.txt"
		},
		{
		    "id": 17,
		    "name": "Waitomo",
		    "description": "Pictures from Waitomo, New Zealand",
		    "color": {
			"dark": "var(--cs1-yellow-dark)",
			"primary": "var(--cs1-yellow)",
			"light": "var(--cs1-yellow-light)",
			"secondary": "var(--cs1-red-light)"
		    },
		    "thumbnail": "/data/project/photo/07-waitomo/thmb.jpg",
		    "images": [
			"/data/project/photo/07-waitomo/1.jpg",
			"/data/project/photo/07-waitomo/2.jpg",
			"/data/project/photo/07-waitomo/3.jpg"
		    ],
		    "code": "/data/project/photo/07-waitomo/code.txt"
		}
	    ]
	},
	{
	    "theme": "charts",
	    "content": [
		{
		    "id": 21,
		    "name": "Tarawera River",
		    "description": "Pictures from the Tarawera River, New Zealand",
		    "color": {
			"dark": "var(--cs1-yellow-dark)",
			"primary": "var(--cs1-yellow)",
			"light": "var(--cs1-yellow-light)",
			"secondary": "var(--cs1-red-light)"
		    },
		    "thumbnail": "/data/project/charts/01-tarawera/thmb.jpg",
		    "images": [
			"/data/project/charts/01-tarawera/1.jpg",
			"/data/project/charts/01-tarawera/2.jpg",
			"/data/project/charts/01-tarawera/3.jpg"
		    ],
		    "code": "/data/project/charts/01-tarawera/code.txt"
		},
		{
		    "id": 22,
		    "name": "Kepler Track",
		    "description": "Pictures from the Kepler Track, New Zealand",
		    "color": {
			"dark": "var(--cs1-yellow-dark)",
			"primary": "var(--cs1-yellow)",
			"light": "var(--cs1-yellow-light)",
			"secondary": "var(--cs1-red-light)"
		    },
		    "thumbnail": "/data/project/charts/02-kepler/thmb.jpg",
		    "images": [
			"/data/project/charts/02-kepler/1.jpg",
			"/data/project/charts/02-kepler/2.jpg",
			"/data/project/charts/02-kepler/3.jpg"
		    ],
		    "code": "/data/project/charts/02-kepler/code.txt"
		},
		{
		    "id": 23,
		    "name": "Lake Waikaremoana",
		    "description": "Pictures from the Lake Waikaremoana, New Zealand",
		    "color": {
			"dark": "var(--cs1-yellow-dark)",
			"primary": "var(--cs1-yellow)",
			"light": "var(--cs1-yellow-light)",
			"secondary": "var(--cs1-red-light)"
		    },
		    "thumbnail": "/data/project/charts/03-lake_waikaremoana/thmb.jpg",
		    "images": [
			"/data/project/charts/03-lake_waikaremoana/1.jpg",
			"/data/project/charts/03-lake_waikaremoana/2.jpg",
			"/data/project/charts/03-lake_waikaremoana/3.jpg"
		    ],
		    "code": "/data/project/charts/03-lake_waikaremoana/code.txt"
		}
	    ]
	},
	{
	    "theme": "interactive",
	    "content": [
		{
		    "id": 31,
		    "name": "Tarawera River",
		    "description": "Pictures from the Tarawera River, New Zealand",
		    "color": {
			"dark": "var(--cs1-yellow-dark)",
			"primary": "var(--cs1-yellow)",
			"light": "var(--cs1-yellow-light)",
			"secondary": "var(--cs1-red-light)"
		    },
		    "thumbnail": "/data/project/interactive/01-tarawera/thmb.jpg",
		    "images": [
			"/data/project/interactive/01-tarawera/1.jpg",
			"/data/project/interactive/01-tarawera/2.jpg",
			"/data/project/interactive/01-tarawera/3.jpg"
		    ],
		    "code": "/data/project/interactive/01-tarawera/code.txt"
		},
		{
		    "id": 32,
		    "name": "Kepler Track",
		    "description": "Pictures from the Kepler Track, New Zealand",
		    "color": {
			"dark": "var(--cs1-yellow-dark)",
			"primary": "var(--cs1-yellow)",
			"light": "var(--cs1-yellow-light)",
			"secondary": "var(--cs1-red-light)"
		    },
		    "thumbnail": "/data/project/interactive/02-kepler/thmb.jpg",
		    "images": [
			"/data/project/interactive/02-kepler/1.jpg",
			"/data/project/interactive/02-kepler/2.jpg",
			"/data/project/interactive/02-kepler/3.jpg"
		    ],
		    "code": "/data/project/interactive/02-kepler/code.txt"
		},
		{
		    "id": 33,
		    "name": "Lake Waikaremoana",
		    "description": "Pictures from the Lake Waikaremoana, New Zealand",
		    "color": {
			"dark": "var(--cs1-yellow-dark)",
			"primary": "var(--cs1-yellow)",
			"light": "var(--cs1-yellow-light)",
			"secondary": "var(--cs1-red-light)"
		    },
		    "thumbnail": "/data/project/interactive/03-lake_waikaremoana/thmb.jpg",
		    "images": [
			"/data/project/interactive/03-lake_waikaremoana/1.jpg",
			"/data/project/interactive/03-lake_waikaremoana/2.jpg",
			"/data/project/interactive/03-lake_waikaremoana/3.jpg"
		    ],
		    "code": "/data/project/interactive/03-lake_waikaremoana/code.txt"
		}
	    ]
	}
    ]}
;