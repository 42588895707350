// Import required libraries using package manager
import React from "react";
import { render } from "react-dom";
import Nav from "./Nav.js";

class About extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="about-page">
        <Nav content={this.props.content} theme={this.props.theme} />

        <div className="tg-bar-main" />

        <div className="about-title">
          <h1 className="caption-title ">About Information Art House</h1>

          <h3 className="caption-description ">Blah blah blah</h3>
        </div>

        <div className="about-code">
          <code id="about-code-block" />
        </div>
      </div>
    );
  }
}

export default About;
