// Import required libraries using package manager
import React from "react";
import { render } from "react-dom";
import Nav from "./Nav.js";

class Contact extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="contact-page">
        <Nav content={this.props.content} theme={this.props.theme} />

        <div className="tg-bar-main" />
        <div className="contact-title">
          <h1 className="caption-title ">Contact us!</h1>

          <h3 className="caption-description ">Blah blah blah</h3>
        </div>

        <div className="contact-code">
          <code id="contact-code-block" />
        </div>
      </div>
    );
  }
}

export default Contact;
