import React from "react";
import { render } from "react-dom";
import { Link } from "@reach/router";

class ProjectCard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Link to={"/project/" + this.props.theme + "/" + this.props.id}>
        <figure className="card">
          <img
            className="card-image image"
            src={window.location.origin + this.props.image}
            alt={this.props.caption}
          />
          <figcaption className="caption">
            <h3 className="caption-title">{this.props.caption}</h3>
          </figcaption>
        </figure>
      </Link>
    );
  }
}

export default ProjectCard;
